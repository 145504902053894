import styled from 'styled-components';
// import BannerPic from './img/lightup-back.jpg';
// import BannerBG from './img/header-bg.jpg';
const BannerPic =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1561309601/light-up-cl/lightup-back-med_vdl8hm.jpg';

const BannerWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 200px;
  &::before {
    ${
      '' /* content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.yellow}; */
    }
    ${'' /* background-image: url(${BannerPic}); */}
    ${
      '' /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0), url(${BannerBG}));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
        url(${props.cover}); */
    }
  ${
    '' /* -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
  }
    ${
      '' /* background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${BannerBG});
    background: none;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(90%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
    background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); */
    }
  }
  ${
    '' /* &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;

    background: -moz-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 90%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(0,0,0,0)), color-stop(90%,rgba(0,0,0,1)));
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0));
    background-image: url(${BannerPic});
  } */
  }
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.yellow};
  background-image: url(${BannerPic});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1440px) {
    background-size: 130vh;
  }
  @media (max-width: 1440px) {
    padding-bottom: 320px;
    background-size: 125vh;
  }
  @media (max-width: 1200px) {
    padding-top: 150px;
    padding-bottom: 300px;
    background-size: 115vh;
  }
  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 300px;
    background-size: 100vh;
  }
  @media (max-width: 768px) {
    background-image: none;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 200px;
  }
  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 990px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        margin-right: 30px;
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${
          '' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */
        }
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
`;
export const BgImageWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: -2px -1px;
    @media (max-width: 480px) {
      margin: -2px -1px;
    }
  }
`;
export default BannerWrapper;
