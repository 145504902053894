import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import SinglePostWrapper, {
  SingleBannerWrapper,
  GridContainer,
  PostWrapper,
  SingleWrapper
} from './single-banner.style';

const SingleBanner = ({ post, prefix, src, alt }) => {
  if (post) {
    return (
      <SinglePostWrapper>
        <Container>
          <GridContainer template='auto'>
            <PostWrapper featured>
              <div className='resource-img'>
                <Image
                  src={post.frontmatter.thumbnail || post.frontmatter.cover}
                  alt={`MedLight ${post.frontmatter.title}`}
                />
              </div>
              <div className='resource-labels'>
                {post.frontmatter.tags.map((tag, index) => {
                  const isLast = index === post.frontmatter.tags.length - 1;
                  return (
                    <Fragment key={`${post.frontmatter.slug}-${tag}`}>
                      <span>{tag}</span>
                      {!isLast && <span className='divider'>|</span>}
                    </Fragment>
                  );
                })}
              </div>
              <div className='resource-info'>
                <Link to={`/${prefix || 'blog'}/${post.frontmatter.slug}`}>
                  <h2>{post.frontmatter.title}</h2>
                </Link>
                <p>{post.excerpt}</p>
              </div>
            </PostWrapper>
          </GridContainer>
        </Container>
      </SinglePostWrapper>
    );
  }
  return (
    <SingleBannerWrapper>
      <Container>
        <GridContainer template='auto'>
          <SingleWrapper featured>
            <a
              href='https://www.medlight.cl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='resource-img'>
                <Image src={src} alt={alt} />
              </div>
            </a>
          </SingleWrapper>
        </GridContainer>
      </Container>
    </SingleBannerWrapper>
  );
};

export default SingleBanner;
