import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import GlideCarousel from '../../components/GlideCarousel';
import GlideSlide from '../../components/GlideCarousel/glideSlide';

// import ProdImage1 from './img/prod-1.png';
// import Brand1 from './img/brand-1.png';

import {
  ProductSectionWrapper,
  ProductItem,
  ProductImageWrapper,
  PrevButton,
  NextButton
  // BrandWrapper,
  // BrandLogo,
  // BrandDetails,
} from './products-section.style';

// const PRODUCTS = [
//   {
//     productLogo: ProdImage1,
//     productName: 'Free Software Advice',
//     productDetails: 'Top Rated App Development Companies USA',
//     brandLogo: Brand1,
//     brandName: 'Producto',
//     date: 'The Jury 2018'
//   },
//   {
//     productLogo: ProdImage1,
//     productName: 'Free Software Advice',
//     productDetails: 'Top Rated App Development Companies USA',
//     brandLogo: Brand1,
//     brandName: 'Producto',
//     date: 'The Jury 2018'
//   },
//   {
//     productLogo: ProdImage1,
//     productName: 'Free Software Advice',
//     productDetails: 'Top Rated App Development Companies USA',
//     brandLogo: Brand1,
//     brandName: 'Producto',
//     date: 'The Jury 2018'
//   },
//   {
//     productLogo: ProdImage1,
//     productName: 'Free Software Advice',
//     productDetails: 'Top Rated App Development Companies USA',
//     brandLogo: Brand1,
//     brandName: 'Producto',
//     date: 'The Jury 2018'
//   }
// ];

const ProductsSection = ({
  products,
  secTitleWrapper,
  secTitle,
  secDescription,
  productLogoStyle,
  productNameStyle,
  productDetailsStyle
  // brandLogoStyle,
  // brandNameStyle,
  // productDateStyle
}) => {
  // Carousel Options
  const carouselOptions = {
    type: 'carousel',
    autoplay: 4000,
    perView: 4,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 3
      },
      767: {
        perView: 2
      },
      500: {
        perView: 1
      }
    }
  };

  return (
    <ProductSectionWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Líneas y productos destacados" />
          <Text {...secDescription} content="Algunos de nuestros equipos más solicitados" />
        </Box>

        <GlideCarousel
          carouselSelector="product-carousel"
          options={carouselOptions}
          prevButton={(
            <PrevButton>
              <span />
            </PrevButton>
)}
          nextButton={(
            <NextButton>
              <span />
            </NextButton>
)}
        >
          <>
            {products.map(({ frontmatter }) => (
              <GlideSlide key={`product-item-${frontmatter.slug}`}>
                <ProductItem>
                  <ProductImageWrapper>
                    <Image
                      src={frontmatter.thumbnail}
                      alt={`light-up-${frontmatter.title}`}
                      title={`light-up ${frontmatter.title}`}
                      {...productLogoStyle}
                    />
                  </ProductImageWrapper>
                  <Link to={`/producto/${frontmatter.slug}`}>
                    <Heading as="h3" content={frontmatter.title} {...productNameStyle} />
                  </Link>
                  <Text content={frontmatter.description} {...productDetailsStyle} />
                </ProductItem>
              </GlideSlide>
            ))}
          </>
        </GlideCarousel>
      </Container>
    </ProductSectionWrapper>
  );
};

ProductsSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  productLogoStyle: PropTypes.object,
  productNameStyle: PropTypes.object,
  productDetailsStyle: PropTypes.object,
  brandLogoStyle: PropTypes.object,
  brandNameStyle: PropTypes.object,
  productDateStyle: PropTypes.object
};

ProductsSection.defaultProps = {
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: '90px'
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: 'headingColor',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px']
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: 'textColor',
    lineHeight: '1.5',
    mb: '0'
  },
  productLogoStyle: {
    ml: 'auto',
    mr: 'auto',
    mb: '25px'
  },
  productNameStyle: {
    fontSize: ['16px', '16px', '18px', '20px'],
    fontWeight: '600',
    color: 'headingColor',
    lineHeight: '1.35',
    textAlign: 'center',
    mb: '17px'
  },
  productDetailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px'],
    color: 'textColor',
    lineHeight: '1.5',
    textAlign: 'center',
    mb: '0'
  },
  brandLogoStyle: {},
  brandNameStyle: {
    fontSize: '16px',
    color: 'labelColor',
    lineHeight: '1.35',
    fontWeight: '600',
    mb: '4px'
  },
  productDateStyle: {
    fontSize: '12px',
    color: '#labelColor',
    lineHeight: '1.35',
    mb: '0'
  }
};

export default ProductsSection;
