import styled, { css } from 'styled-components';

const SinglePostWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  .inner-heading {
    h3 {
      color: ${({ theme }) => theme.colors.headingColor};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.labelColor};
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
    }
  }
  .inner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 667px) {
      justify-content: inherit;
    }
    a {
      color: ${({ theme }) => theme.colors.headingColor};
      text-decoration: none;
      font-weight: 900;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const SingleBannerWrapper = styled.div`
  padding-top: 2rem;
  ${'' /* padding-bottom: 4rem; */}
  .inner-heading {
    h3 {
      color: ${({ theme }) => theme.colors.headingColor};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.labelColor};
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
    }
  }
  .inner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 667px) {
      justify-content: inherit;
    }
    a {
      color: ${({ theme }) => theme.colors.headingColor};
      text-decoration: none;
      font-weight: 900;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  ${({ template }) => {
    if (template === undefined) {
      return css`
        grid-auto-flow: row;
        grid-template-areas: 'a a';
      `;
    }
    if (typeof template === 'string') {
      return css`
        grid-template-columns: ${template};
      `;
    }
    return css`
      grid-template-columns: ${template[0]};
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-template-columns: ${template[1]};
        grid-template-rows: ${template[1]};
      }
    `;
  }}}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export const PostWrapper = styled.div`
  position: relative;
  padding: 3.75rem 1.875rem;
  min-height: 30vh;
  height: 100%;
  z-index: 1;
  border-radius: 3px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.inactiveIcon};
  background-color: ${({ theme }) => theme.colors.background};
  transition: 0.2s all;
  @media only screen and (max-width: 667px) {
    margin-top: 20px;
  }
  .resource-img {
    margin-bottom: 25px;
    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      border-radius: 3px;
      object-fit: cover;
      box-shadow: ${({ theme }) => theme.colors.greyBoxShadow};
    }
  }
  .resource-labels {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.labelColor};
    span {
      font-size: 12px;
      line-height: 20px;
      font-weight: 900;
      text-transform: uppercase;
      &.divider {
        margin: 0 8px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .resource-info {
    h2,
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.textColor};
      line-height: 25px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 32px;
    }
    h3,
    h4 {
      font-size: 20px;
    }
    p {
      color: ${({ theme }) => theme.colors.textColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}

  ${({ featured }) =>
    featured &&
    css`
      min-height: 60vh;
      margin-bottom: 2.5rem;
      background-color: transparent;
      padding: 0;
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .resource-img img {
        max-height: 400px;
      }
      .resource-info p {
        font-size: 1.25rem;
        line-height: 24px;
      }
    `}

  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.lightBlue};
    h2,
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

export const SingleWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;
  border-radius: 3px;
  transition: 0.2s all;
  @media only screen and (max-width: 667px) {
    margin-top: 20px;
  }
  .resource-img {
    margin-bottom: 25px;
    img {
      width: 100%;
      height: 100%;
      ${'' /* max-height: 300px; */}
      border-radius: 3px;
      object-fit: cover;
      transition: 0.2s all;
      &:hover {
        box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
        transform: translateY(-3px);
      }
    }
  }
`;

export default SinglePostWrapper;
