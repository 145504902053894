import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';

import Banner from 'common/source/containers/BannerSection';
import PortfolioSection from 'common/source/containers/PortfolioSection';
import TimelineSection from 'common/source/containers/TimelineSection';
import ContactSections from 'common/source/containers/Contact';
import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';

import ProductsSection from 'common/source/containers/ProductsSection';
import SingleBanner from 'common/source/containers/SingleBanner';
import TopBar from 'common/source/containers/TopBar';

// import TimelineSection from 'common/source/containers/Saas/TimelineSection';
// import FeatureSection from 'common/source/containers/Feature';
// import TestimonialSection from 'common/source/containers/Testimonials';
// import BannerSection from 'common/source/containers/SaasTwo/Banner';
// import WorkingProcessSection from 'common/source/containers/SaasTwo/WorkingProcess';
// import PricingSection from 'common/source/containers/SaasTwo/Pricing';
// import PartnerSection from 'common/source/containers/SaasTwo/Partner';
// import FaqSection from 'common/source/containers/SaasTwo/Faq';
// import TrialSection from 'common/source/containers/SaasTwo/Trial';
// import InfoSection from 'common/source/containers/SaasTwo/Info';
// import FeatureSection from 'common/source/containers/SaasTwo/Feature';
// import UpdateScreen from 'common/source/containers/SaasTwo/UpdateScreen';
// import TestimonialSection from 'common/source/containers/SaasTwo/Testimonial';
// import Footer from 'common/source/containers/SaasTwo/Footer';

import SEO from '../components/SEO';

class HomePage extends React.Component {
  render() {
    const { data } = this.props;
    const { products, projects } = data;
    const featuredProjects = projects.edges.map(edge => edge.node);
    const featuredProducts = products.edges.map(edge => edge.node);
    // console.log({ featuredProjects, featuredProducts });
    const showTopBar = true;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            {showTopBar && (
              <TopBar>
                <div>
                  Horario de atención Lunes a Viernes: Presencial 9:30 a 14:00
                  hrs – Remoto 15:00 a 18:00 hrs
                  {' '}
                  <Link to='/contacto?product=topbar'>Contáctanos</Link>
                </div>
              </TopBar>
            )}
            <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner />

            <SingleBanner
              src='https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1588713035/medlight-cl/medlight_banner_t1lsuv.jpg'
              alt='Medlight Banner'
            />

            <PortfolioSection projects={featuredProjects} />
            <TimelineSection />
            <PartnerSection />
            {/* <FeatureSection /> */}
            <ProductsSection products={featuredProducts} />

            <ContactSections />
            {/*
            <InfoSection />
            <UpdateScreen />
            <TestimonialSection />
            <BannerSection />
            <WorkingProcessSection />
            <PricingSection />
            <FaqSection />
            <TrialSection />
          */}

            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default HomePage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query HomeQuery {
    products: allMarkdownRemark(
      limit: 5
      filter: {
        frontmatter: {
          featured: { eq: true }
          type: { eq: "PRODUCT" }
          published: { eq: true }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            slug
            type
            date
            description
            thumbnail
          }
        }
      }
    }
    projects: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { featured: { eq: true }, type: { eq: "PROJECT" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            slug
            type
            date
            description
            thumbnail
          }
        }
      }
    }
  }
`;
