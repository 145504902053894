import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';

import PartnerSectionWrapper from './partner.style';
// import Partner from './img/partner.png';
// import GrandMA3 from './img/grandMA3.png';
const GrandMA3 =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1561308302/light-up-cl/grandMA3_wnxmph.png';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  right,
  sectionSubTitle
}) => {
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          {!right && (
            <Box {...col} {...imageArea}>
              <Image src={GrandMA3} alt="Light-Up GrandMA3" />
            </Box>
          )}
          <Box {...col} {...textArea}>
            <Text {...sectionSubTitle} content="CONSOLAS" />
            <Heading
              {...title}
              content={`Trabajo sin fronteras.
            Robusto y duradero`}
            />
            <Text
              {...description}
              content="Fundada en el legado de las consolas GranMA anteriores, la GranMA3 representa un replanteamiento radical de lo que es posible desde una plataforma de control de iluminación. La nueva y elegante arquitectura de sistema incorpora un nuevo dispositivo, manejo de funciones y efectos en su corazón."
            />
            <Box>
              <Link to="/productos/consolas">
                <Button {...button} variant="hoverShadow" title="MÁS SISTEMAS DE CONTROL" />
              </Link>
            </Box>
          </Box>
          {/*
                  {right && (
            <Box {...col} {...imageArea}>
              <Image src={GrandMA3} alt="Light-UP grandMA3" />
            </Box>
          )}

         */}
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  sectionSubTitle: PropTypes.object
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  // imageAreaRow: {
  //   flexDirection: 'row-reverse'
  // },
  col: {
    pr: '15px',
    pl: '15px'
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%']
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0']
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25'
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px'
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    minWidth: '150px'
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    // textAlign: 'center',
    fontSize: '16px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '10px'
  }
};

export default PartnerSection;
