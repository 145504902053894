import styled from 'styled-components';

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 2.5rem;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.85rem;
  font-weight: 500;
  a {
    color: ${({ theme }) => theme.colors.yellow};
    font-weight: 700;
    text-decoration: underline;
  }
  .close {
    vertical-align: middle;
    button {
      ${'' /* background: red; */}
      background: rgba(255, 255, 255, 0.3);
      border: none;
      border-radius: 100px;
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      align-content: inherit;
      vertical-align: middle;
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-weight: 700;
      font-size: 0.85rem;
      transition: 0.35s all;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
        border: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export default TopBarWrapper;
