import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
// import Link from 'reusecore/src/elements/Link';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Button from 'reusecore/src/elements/Button';

import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';
import PorfolioSectionWrapper from './portfolioSection.style';

import ProjectImage1 from './img/project-img1.png';

const placeholders = [
  {
    id: 1,
    title: 'Real home corporation',
    thumbnail_url: ProjectImage1,
    date: 'November 02, 2018',
    postLink: '#1'
  },
  {
    id: 2,
    title: 'Sheltech developer ltd.',
    thumbnail_url: ProjectImage1,
    date: 'November 12, 2018',
    postLink: '#2'
  },
  {
    id: 3,
    title: 'Alt architecture co.',
    thumbnail_url: ProjectImage1,
    date: 'December 09, 2018',
    postLink: '#3'
  }
];

const PortfolioSection = ({
  projects,
  row,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  projectTitle,
  projectMeta,
  outlineBtnStyle
}) => {
  return (
    <PorfolioSectionWrapper id="portfolioSection">
      <Container>
        <Box {...sectionHeader}>
          <Text content="PORTAFOLIO PROYECTOS" {...sectionSubTitle} />
          <Heading content="Algunos de nuestros proyectos" {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {projects.length > 0 &&
            projects.map(({ frontmatter }) => (
              <FeatureBlock
                key={`key-${frontmatter.slug}`}
                id={`post_id-${frontmatter.slug}`}
                className="project__post"
                icon={(
                  <Image
                    src={frontmatter.thumbnail}
                    alt={`Light-Up proyecto ${frontmatter.title}`}
                    className="project__image"
                  />
)}
                title={(
                  <Link to={`/proyecto/${frontmatter.slug}`} style={projectTitle}>
                    {frontmatter.title}
                  </Link>
)}
                description={<Text content={frontmatter.description} {...projectMeta} />}
              />
            ))}
        </Box>
        <Box className="row" style={{ textAlign: 'right', marginTop: '1rem' }}>
          <Link to="/proyectos">
            <Button
              title="Revisar más proyectos"
              variant="textButton"
              icon={<i className="flaticon-next" />}
              {...outlineBtnStyle}
            />
          </Link>
        </Box>
      </Container>
    </PorfolioSectionWrapper>
  );
};

// PortfolioSection style props
PortfolioSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  projectTitle: PropTypes.object,
  projectMeta: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

// PortfolioSection default style
PortfolioSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px']
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '10px'
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0'
  },
  // Project post row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-12px',
    mr: '-12px'
  },
  col: {},
  // Project post title default style
  projectTitle: {
    // fontSize: ['20px', '24px'],
    fontSize: '20px',
    fontWeight: '400',
    color: 'white',
    lineHeight: '1.5',
    // mb: '10px',
    marginBottom: '10px',
    letterSpacing: '-0.020em'
  },
  // Project post description default style
  projectMeta: {
    fontSize: '16px',
    lineHeight: '1',
    color: 'rgba(255, 255, 255, 0.5)',
    mb: 0
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
    color: 'primary',
    p: '5px 10px'
  }
};

export default PortfolioSection;
